a {
  padding: 20px;
  display: inline-block;
  overflow: hidden;
  border-radius: 13px;
  width: 250px; height: 83px;
}

img.download {
  border-radius: 13px;
  width: 250px;
  height: 83px;
}
