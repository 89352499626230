.App {
  text-align: center;
  /* padding: 20px; */
}

.App-logo {
  width: 200px;
  border-radius: 20%;
  display: block;
  touch-action: none;
  pointer-events: none;
}

.App-body {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  /* No Select */
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

h1 {
  font-size: 60px;
}

h2 {
  font-size: 30px;
}

h3 {
  font-size: 15px;
}
